import {Link} from 'react-router-dom'
import axios from "axios";
import {useContext, useEffect, useRef, useState} from "react";
import {Language} from "../LangContext/LangaugeContext";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {baseURL, LANGUAGES, SET_LOCALE} from "../API/Apis";

export default function Header ()
{
    const locale = useContext(Language);
    const localeVal = locale.locale;
    const navigate = useNavigate()
    const last_segment = window.location.pathname.split(`/`);
    const [languages , setLanguages] = useState([]);
    const {t , i18n} = useTranslation();

    useEffect(()=>{
        axios.get(`${baseURL}/${LANGUAGES}`).then((data)=>setLanguages(data.data[0]))
    },[])

    const setLocale = (localeLang)=>{
        axios.post(`${baseURL}/${SET_LOCALE}`,{
            'locale':localeLang
            // localeLang.target.innerText
        }).
        then((data)=>
        {
            locale.setLocale(data.data.data);
            if(last_segment[2]===undefined)
                 {
                     navigate(`${data.data.data}/${last_segment[1]}`)
                 }
                 else if(last_segment[3]===undefined){
                     navigate(`${data.data.data}/${last_segment[2]}`)
                 }else{
                navigate(`${data.data.data}/${last_segment[2]}/${last_segment[3]}`)
                 }
            localStorage.setItem('locale', JSON.stringify(data.data.data));
           // window.location.reload()
        })
    }

    useEffect(()=>{
        if(localeVal === 'ar') {
            i18n.changeLanguage('ar')
            require('../styles/css/ar.css');
        } else {
            i18n.changeLanguage('en')
            // require('../styles/css/en.css');
        }

    },[localeVal])

    // useEffect(() => {
    //     locale.setLocale( JSON.parse(localStorage.getItem('locale')))
    // }, []);

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () =>{
        const element = document.getElementById('mainMenu');

        setIsNavCollapsed(!isNavCollapsed)
        if(isNavCollapsed===true)
        {
            element.classList.add('in');

        }else{
            element.classList.remove('in');

        }
    };
    const  handleLink = () =>{
        const element = document.getElementById('mainMenu');
        element.classList.remove('in');
    }

    return (
        <header>
            <div className="container">
                <div className="row">
                    <nav className="navbar">
                        <div className="">
                            <div className="navbar-header">
                                <button className="navbar-toggle collapsed" type="button"  data-toggle="collapse" data-target="#mainMenu" aria-controls="mainMenu" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>

                            <span className="sr-only">Toggle navigation</span>
                                    <span key='first' className="icon-bar"></span>
                                    <span key='second' className="icon-bar"></span>
                                    <span key='third' className="icon-bar"></span>
                                </button>
                                <Link className="logo" to="#"><img className="img-responsive" src={require('../styles/images/en/logo.png')} alt=""/></Link>
                            </div>
                            <div   className="collapse navbar-collapse" id="mainMenu">
                                <ul   className="nav navbar-nav">
                                    <li key='1' onClick={handleLink}><Link  key='6' to={`${localeVal}/`}>{t('home')}</Link></li>
                                    <li key='2' onClick={handleLink}><Link  key='7' to={`${localeVal}/about-company`}>{t('about_us')}</Link></li>
                                    <li key='3' onClick={handleLink}><Link  key='8' to={`${localeVal}/products`}>{t('products')}</Link></li>
                                    <li key='4' onClick={handleLink}><Link  key='9' to={`${localeVal}/brands`}>{t('brands')}</Link></li>
                                    <li key='5'><Link key='10' to={`${localeVal}/contact`}>{t('contact_us')}</Link></li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    )
}

// import React, { useState } from 'react';
// //import Logo from '../images/logo_512x512.png';
//
// const TopNav = props => {
//     const [isNavCollapsed, setIsNavCollapsed] = useState(true);
//
//     const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
//
//     return (
//         <nav class="navbar navbar-expand-lg navbar-light bg-light rounded">
//             <a class="navbar-brand text-info font-weight-bolder" href="/">
//                 <img  alt="Logo" width="36" height="36" className="vertical-align-middle" />
//                 <span className="">Discounter</span>
//             </a>
//             <button class="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
//                 <span class="navbar-toggler-icon">hello</span>
//             </button>
//
//             <div class={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample09">
//                 <a className="nav-link text-info" href="/contact">Support</a>
//                 <a className="nav-link text-info" href="/login">Login</a>
//                 <a href="/request-demo" className="btn btn-sm btn-info nav-link text-white" >Request demo</a>
//             </div>
//         </nav>
//     );
// }
//
// export default TopNav;