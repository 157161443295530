import {useContext, useEffect, useState} from "react";
import {Link, useParams} from 'react-router-dom'
import axios from "axios";
import CarouselProducts from "../Layouts/CarouselProducts";
import {Helmet} from "react-helmet-async";
import {imageUrl} from "../imageUrl";
import {Language} from "../LangContext/LangaugeContext";
import {baseURL, RELATED_PRODUCTS} from "../API/Apis";
import { useLocation } from "react-router-dom";

export  default function RelatedProducts()
{
    const {categoryId} = useParams();
    const locale = useContext(Language);
    const localeVal = locale.locale;
    const [products , setProducts] = useState([]);
    const [categoryData , setCategoryData] = useState([]);
    const location = useLocation();
    const { brandData } = location.state
    const brandId = brandData[0].id;

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${RELATED_PRODUCTS}/${brandId}/${categoryId}`,{
            }).then((res) => {
                setCategoryData(res.data.categoryData);
                setProducts(res.data.products)
                 window.localStorage.setItem('categoryData',res.data.categoryData);
                window.localStorage.setItem('related product',res.data.products);
            });
        };
        getData();
    }, []);
    return (
        <div>
            <Helmet>
                <title>Products</title>
                <link rel="canonical" href="https://www.tacobell.com/" />
                <meta name="description" content="products"/>
            </Helmet>
            <main id="insideMain" tabIndex="-1">
                <div className="container">
                    <div className="row">
                        <article className="col-xs-12">
                            <h1>
                                {
                                    brandData.map((brand , index)=>
                                        brand.translations.map((translation , index)=>
                                            <Link to={`/${localeVal}/categories/${brand.id}`} key={index}
                                            >{translation.locale === localeVal ? translation.brand_name : ''} </Link>
                                        )
                                    )
                                }
                                /
                                {
                                    categoryData.map((category , index)=>
                                        category.translations.map((translation , index)=>
                                            <p key={index}
                                            >{translation.locale === localeVal ? translation.category_name : ''} </p>
                                        )
                                    )
                                }
                            </h1>
                            <ul className="proList">
                                {
                                    products.map((product,index)=>
                                        <li key={index}><Link to={`/${localeVal}/product-inside/${product.id}`} state={{categoryData:categoryData , brandData:brandData}}>
                                            <img src={`${imageUrl}products/${product.image}`} alt=""/></Link>
                                            {product.translations.map((translation , index)=>
                                                    <div key={index}>
                                                        {translation.locale === localeVal ? <span>{translation.product_name}</span> : '' }
                                                    </div>
                                            )}
                                        </li>
                                    )}
                            </ul>
                        </article>
                    </div>
                </div>
            </main>
            <CarouselProducts/>
        </div>
    )
}