import {useContext, useEffect, useState} from "react";
import {Link} from 'react-router-dom'
import axios from "axios";
import CarouselProducts from "../Layouts/CarouselProducts";
import {Helmet} from "react-helmet-async";
import {imageUrl} from "../imageUrl";
import {Language} from "../LangContext/LangaugeContext";
import {baseURL, WEBSITE_BRANDS} from "../API/Apis";
import { useTranslation } from 'react-i18next';

export  default function Brands()
{
    const locale = useContext(Language);
    const localeVal = locale.locale;
    const [brands , setBrands] = useState([]);
    const {t , i18n} = useTranslation();

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${WEBSITE_BRANDS}`,{
            }).then((res) => {
                setBrands(res.data.brands)
                window.localStorage.setItem('brands',res.data.brands);
            });
        };
        getData();
    }, []);
    return (
        <div>
            <Helmet>
                <title>Products</title>
                <link rel="canonical" href="https://www.tacobell.com/" />
                <meta name="description" content="products"/>
            </Helmet>
            <main id="insideMain" tabIndex="-1">
                <div className="container">
                    <div className="row">
                        <article className="col-xs-12">
                            <h1>{t('our_partners')}</h1>
                            <ul className="proList">
                                {
                                    brands.map((brand,index)=>
                                        <li key={index}><Link to={`/${localeVal}/categories/${brand.id}`}>
                                            <img src={`${imageUrl}brands/${brand.brand_image}`} alt=""/></Link>
                                            {/*{brand.translations.map((translation , index)=>*/}
                                            {/*    <span key={index}>{translation.locale === localeVal ? translation.brand_name : ''} </span>*/}
                                            {/*)}*/}
                                        </li>
                                    )}
                            </ul>
                        </article>

                    </div>
                </div>
            </main>
            <CarouselProducts/>
        </div>
    )
}