import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from  '../src/locale/en.json';
import translationAr from  '../src/locale/ar.json';
import LanguageDetector from 'i18next-browser-languagedetector';
const resources = {
    en: {
        translation: translationEn
    },
    ar: {
        translation: translationAr
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: "ar",

        interpolation: {
            escapeValue: false
        },
        react:{
            useSuspense:false
        }
    });
export default i18n;