import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {Link, useLocation, useParams} from "react-router-dom";
import CarouselProducts from "../Layouts/CarouselProducts";
import {Helmet} from "react-helmet-async";
import {imageUrl} from "../imageUrl";
import {Language} from "../LangContext/LangaugeContext";
import {baseURL, WEB_PRODUCTS_DATA} from "../API/Apis";

export default function ProductInside ()
{
    const locale = useContext(Language);
    const localeVal = locale.locale;
    const [productData , setProductData] = useState([]);
    const [translations , setTranslations] = useState([]);
    const {productId} = useParams();
    const location = useLocation();
    const { brandData } = location.state
    const {categoryData} = location.state;
    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${WEB_PRODUCTS_DATA}/${productId}`,{
            }).then((res) => {
                setProductData(res.data)
                setTranslations(res.data.translations);
                localStorage.setItem('data',res.data);
                localStorage.setItem('translations',res.data.translations);
            });
        };
        getData();
    }, []);
    return (
        <div>
            <Helmet>
                <title>Product details</title>
                <link rel="canonical" href="https://www.tacobell.com/" />
                <meta name="description" content={productData.meta_decsription}/>
                <meta name="title" content={productData.meta_title}/>
            </Helmet>
            <main id="insideMain" tabIndex="-1">
                <div className="container">
                    <div className="row">
                        <article className="col-xs-12">
                            <h1>

                                {
                                    translations.map((translation , index)=>
                                       translation.locale === localeVal ? translation.product_name: ''
                                    )}
                                <br/><br/>

                                {
                                    brandData.map((brand , index)=>
                                        brand.translations.map((translation , index)=>
                                          brand.id === productData.brand_id ?
                                              <Link to={`/${localeVal}/categories/${brand.id}`} key={index}
                                              >{translation.locale === localeVal ? translation.brand_name : ''} </Link> :''
                                        )
                                    )
                                }
                                /
                                {
                                    categoryData.map((category , index)=>
                                        category.translations.map((translation , index)=>
                                            <Link key={index} to={`/${localeVal}/products-category/${category.id}`}
                                                  state={{brandData }}
                                            >{translation.locale === localeVal ? translation.category_name : ''} </Link>
                                        )
                                    )
                                }
                                /
                                {
                                    translations.map((translation , index)=>
                                        <p key={index}>{translation.locale === localeVal ? translation.product_name: '' }</p>
                                    )}

                            </h1>

                            <div className="proImg">
                                <img style={{width:'249px' , height:'260px'}}
                               src={`${imageUrl}products/${productData.image}`} alt=""/>

                            </div>
                            {
                                translations.map((translation , index)=>
                                    <div key={index}>
                                        <span>{translation.locale === localeVal ? translation.product_name: '' }</span>
                                        {translation.locale === localeVal ? <p key={index} dangerouslySetInnerHTML={{ __html: productData.product_content }} ></p> : ''}
                                    </div>
                            )}
                        </article>
                    </div>
                </div>
            </main>
            <CarouselProducts/>
        </div>
    )
}