import {useContext, useEffect, useState} from "react";
import {Link, useParams} from 'react-router-dom'
import axios from "axios";
import CarouselProducts from "../Layouts/CarouselProducts";
import {Helmet} from "react-helmet-async";
import {imageUrl} from "../imageUrl";
import {Language} from "../LangContext/LangaugeContext";
import {baseURL, PRODUCTS_CATEGORY} from "../API/Apis";
import { useTranslation } from 'react-i18next';

export  default function Products()
{
    const {categoryId} = useParams();
    const locale = useContext(Language);
    const localeVal = locale.locale;
    const [products , setProducts] = useState([]);
    const {t , i18n} = useTranslation();
    const [categoryData , setCategoryData] = useState([]);
    const [brandData , setBrandData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${PRODUCTS_CATEGORY}/${categoryId}`,{
            }).then((res) => {
                setCategoryData(res.data.categoryData);
                setBrandData(res.data.brandData)
                setProducts(res.data.products)
                window.localStorage.setItem('products-category',res.data[0]);
            });
        };
        getData();
    }, []);

    return (
        <div>
            <Helmet>
                <title>Products</title>
                <link rel="canonical" href="https://www.tacobell.com/" />
                <meta name="description" content="products"/>
            </Helmet>
            <main id="insideMain" tabIndex="-1">
                <div className="container">
                    <div className="row">
                        <article className="col-xs-12">
                            <h1>
                                {
                                    categoryData.map((category , index)=>
                                        category.translations.map((translation , index)=>
                                            translation.locale === localeVal ? translation.category_name : ''
                                        )
                                    )
                                }
                            </h1>

                            <ul className="proList">
                                {
                                    products.map((product,index)=>
                                        <li key={index}><Link to={`/${localeVal}/product-inside/${product.id}`} state={{categoryData:categoryData , brandData:brandData}}>
                                            <img src={`${imageUrl}products/${product.image}`} alt=""/></Link>
                                            {product.translations.map((translation , index)=>
                                                translation.locale === localeVal ? <span key={index}>{translation.product_name} </span> : ''
                                            )}
                                        </li>
                                    )}
                            </ul>
                        </article>
                    </div>
                </div>
            </main>
        </div>
    )
}