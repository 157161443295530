import Content from "./Layouts/Content";
import {Routes , Route} from 'react-router-dom'
import Contact from "./Pages/Contact";
import Products from "./Pages/Products";
import ProductInside from "./Pages/ProductInside";
import Inside from "./Pages/Inside";
import React, {useContext, useEffect} from 'react';
import Header from "./Layouts/Header";
import Slider from "./Layouts/Slider";
import Footer from "./Layouts/Footer";
import {Language} from "./LangContext/LangaugeContext";
import Brands from "./Pages/Brands";
import Categories from "./Pages/Categories";
import RelatedProducts from "./Pages/RelatedProducts";
import AboutCompany from "./Pages/AboutCompany";
import { useTranslation } from 'react-i18next';
import ProductsCategory from "./Pages/ProductsCategory";

function App()
 {
     const locale = useContext(Language);
     const localeVal = locale.locale;
     const {t , i18n} = useTranslation();
     document.documentElement.lang = i18n.language;

     return (
           <div>
               <Header/>
               <Slider/>
               <Routes>
                   <Route exact path={`/${localeVal}`} element={<Content/>}/>
                   <Route path={`/`} element={<Content/>}/>
                   <Route path={`${localeVal}/contact`} element={<Contact/>}/>
                   <Route path={`${localeVal}/products`} element={<Products/>}/>
                   <Route path={`${localeVal}/products-category/:categoryId`} element={<ProductsCategory/>}/>
                   <Route path={`${localeVal}/product-inside/:productId`} element={<ProductInside/>}/>
                   <Route path={`${localeVal}/inside`} element={<Inside/>}/>
                   <Route path={`${localeVal}/brands`} element={<Brands/>}/>
                   <Route path={`${localeVal}/categories/:brandId`} element={<Categories/>}/>
                   <Route path={`${localeVal}/related-products/:brandId/:categoryId`} element={<RelatedProducts/>}/>
                   <Route path={`${localeVal}/about-company`} element={<AboutCompany/>}/>
               </Routes>
               <Footer/>
           </div>
       )
}

export default App;
