import CarouselProducts from "./CarouselProducts";
import {Link} from "react-router-dom";
import {imageUrl} from "../imageUrl";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {baseURL, WEBSITE_BRANDS} from "../API/Apis";
import {Language} from "../LangContext/LangaugeContext";
import { useTranslation } from 'react-i18next';

export default function Content ()
{
    const locale = useContext(Language);
    const localeVal = locale.locale;
    const [brands , setBrands] = useState([]);
    const {t , i18n} = useTranslation();

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${WEBSITE_BRANDS}`,{
            }).then((res) => {
                setBrands(res.data.brands)
                window.localStorage.setItem('brands',res.data.brands);
            });
        };
        getData();
    }, []);
    return (
        <div>
            <main id="homeMain" tabIndex="-1">
                <div className="container">
                    <div className="row">
                        <article className="col-xs-12">
                            <h1>{t("search_with_us")}</h1>
                            <ul className="proList">
                                {
                                    brands.map((brand,index)=>
                                        <li key={index}><Link to={`/${localeVal}/categories/${brand.id}`}>
                                            <img src={`${imageUrl}brands/${brand.brand_image}`} alt=""/></Link>
                                        </li>
                                    )}
                            </ul>
                        </article>
                    </div>
                </div>
            </main>
            <CarouselProducts/>
        </div>
    )
}