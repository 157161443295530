export const baseURL = `https://api.andraos-co.com/api`;
export const imageUrl = 'https://andraos-co.com/andraosImage/';


export const FEATURED_PRODUCTS = 'featured-products';
export const LANGUAGES = 'languages';
export const SET_LOCALE = 'set-locale';
export const WEBSITE_BRANDS = 'website-brands';
export const WEBSITE_CATEGORIES = 'website-categories';
export const SUB_CATEGORIES = 'sub-categories';
export const CONTACT = 'contact';
export const WEB_PRODUCTS_DATA = 'web-product-data';
export const WEBSITE_PRODUCTS = 'website-products';
export const RELATED_PRODUCTS = 'related_products';
export const ABOUT_COMPANY = 'about-company';
export const PRODUCTS_CATEGORY = 'products-category';
