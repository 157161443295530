import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay} from 'swiper'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css'
import '../App.css'


export default function Slider()
{
    return (
            <Swiper
                className="sample-slider"
                modules={[Navigation, Pagination, Autoplay]}
                navigation
                pagination
                style={{direction:"rtl"}}
                loop={true}
                autoplay={{ delay:2000 }}
            >
                <SwiperSlide><img style={{height:'50%' , width:'100%'}}  src={require('../styles/uploads/files/header1.jpg')} /></SwiperSlide>
                <SwiperSlide><img style={{height:'50%' , width:'100%'}} src={require('../styles/uploads/files/header2.jpg')} /></SwiperSlide>
                <SwiperSlide><img style={{height:'50%' , width:'100%'}} src={require('../styles/uploads/files/header3.jpg')} /></SwiperSlide>
                <SwiperSlide><img style={{height:'50%' , width:'100%'}} src={require('../styles/uploads/files/header4.jpg')} /></SwiperSlide>
                <SwiperSlide><img style={{height:'50%' , width:'100%'}} src={require('../styles/uploads/files/header5.jpg')} /></SwiperSlide>
            </Swiper>
    )
}