import Slider from "react-slick";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {imageUrl} from "../imageUrl";
import {Language} from "../LangContext/LangaugeContext";
import {baseURL, FEATURED_PRODUCTS} from "../API/Apis";

export default  function  CarouselProducts (){

    const locale = useContext(Language);
    const localeVal = locale.locale;
    const [products , setProducts] = useState([]);
    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${FEATURED_PRODUCTS}`,{
            }).then((res) => {
                setProducts(res.data.featured)
                window.localStorage.setItem('featured',res.data.featured);
            });
        };
        getData();
    }, []);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        loop:true,
        autoplay:1000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,

                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    return (
            <div className="productsCarousel">
                <div className="container">
                    <Slider {...settings} >
                        {products.map((product , index)=>
                            <div key={index}>
                                <a href={`/${localeVal}/product-inside/${product.id}`} style={{textDecoration:"none", color:"gray",}}>
                                <img style={{width:'250px',height:'250px'}} src={`${imageUrl}products/${product.image}`} alt=""/>
                                {product.translations.map((translation , index)=>
                                    <center key={index}><span>{translation.locale === localeVal ? translation.product_name : ""}</span></center>

                                    )}
                               </a>
                            </div>
                        )}
                    </Slider>
                </div>
            </div>
        )
}