import {useContext, useEffect, useState} from "react";
import {Link} from 'react-router-dom'
import axios from "axios";
import {Helmet} from "react-helmet-async";
import {imageUrl} from "../imageUrl";
import {Language} from "../LangContext/LangaugeContext";
import {baseURL, WEBSITE_PRODUCTS} from "../API/Apis";
import { useTranslation } from 'react-i18next';

export  default function Products()
{
    const locale = useContext(Language);
    const localeVal = locale.locale;
    const [categories , setCategory] = useState([]);
    const {t , i18n} = useTranslation();

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${WEBSITE_PRODUCTS}`,{
            }).then((res) => {
                setCategory(res.data[0])
                window.localStorage.setItem('products',res.data[0]);
            });
        };
        getData();
    }, []);

    return (
       <div>
           <Helmet>
               <title>Products</title>
               <link rel="canonical" href="https://www.tacobell.com/" />
               <meta name="description" content="products"/>
           </Helmet>
           <main id="insideMain" tabIndex="-1">
               <div className="container">
                   <div className="row">
                       <article className="col-xs-12">
                           <h1>{t('our_products')}</h1>


                           <ul className="proList">
                                 {
                                     categories.map((category,index)=>
                                         <li key={index}><Link to={`/${localeVal}/products-category/${category.id}`}>
                                             <img src={`${imageUrl}categories/${category.category_image}`} alt=""/></Link>
                                             {category.translations.map((translation , index)=>
                                                 translation.locale === localeVal ? <span key={index}>{translation.category_name} </span> : ''
                                             )}
                                         </li>
                                     )}
                             </ul>
                       </article>

                       {/*<ReactPaginate*/}
                       {/*    previousLabel={"previous"}*/}
                       {/*    nextLabel={"next"}*/}
                       {/*    breakLabel={"..."}*/}
                       {/*    pageCount={2}*/}
                       {/*    marginPagesDisplayed={2}*/}
                       {/*    pageRangeDisplayed={3}*/}
                       {/*    onPageChange={handlePageClick}*/}
                       {/*    containerClassName={"pagination justify-content-center"}*/}
                       {/*    pageClassName={"page-item"}*/}
                       {/*    pageLinkClassName={"page-link"}*/}
                       {/*    previousClassName={"page-item"}*/}
                       {/*    previousLinkClassName={"page-link"}*/}
                       {/*    nextClassName={"page-item"}*/}
                       {/*    nextLinkClassName={"page-link"}*/}
                       {/*    breakClassName={"page-item"}*/}
                       {/*    breakLinkClassName={"page-link"}*/}
                       {/*    activeClassName={"active"}*/}
                       {/*/>*/}
                   </div>
               </div>
           </main>
           {/*<CarouselProducts/>*/}
       </div>
    )
}