import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {Language} from "../LangContext/LangaugeContext";
import { useTranslation } from 'react-i18next';
import {ABOUT_COMPANY, baseURL} from "../API/Apis";
import CarouselProducts from "../Layouts/CarouselProducts";
import {Link} from "react-router-dom";
import {imageUrl} from "../imageUrl";
import ReactHtmlParser from 'react-html-parser'
import {Helmet} from "react-helmet-async";


export default function AboutCompany ()
{
    const locale = useContext(Language);
    const localeVal = locale.locale;
    const [aboutCompany , setAboutCompany] = useState([]);
    const {t , i18n} = useTranslation();

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${ABOUT_COMPANY}`,{
            }).then((res) => {
                setAboutCompany(res.data.aboutCompany)
                window.localStorage.setItem('about',res.data.aboutCompany);
            });};
        getData();
    }, []);

return (
    <div>
        <Helmet>
            <title>Contact</title>
            <link rel="canonical" href="https://www.tacobell.com/" />

        </Helmet>
        <main id="insideMain" tabIndex="-1">
            <div className="container">
                <div className="row">
                    <article className="col-xs-12">
                        <h1>{t('about-us')}</h1>
                        {aboutCompany.map((about,index)=>
                            about.translations.map((translation , index)=>
                                    translation.locale === localeVal ?
                                        // <p key={index}>{about.content.replace(/(<([^>]+)>)/gi, "")}</p>:""
                                        // <p key={index}>{about.content}</p>:""
                                        <div  key={index}>{ReactHtmlParser(about.content)}</div>:""
                                        // <span key={index} dangerouslySetInnerHTML={{ __html: about.content }} ></span> :""
                            ))}

                    </article>

                </div>
            </div>
        </main>
        <CarouselProducts/>
    </div>

)
}