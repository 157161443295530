import CarouselProducts from "../Layouts/CarouselProducts";
import {Helmet} from "react-helmet-async";

export default function Inside()
{
    return (
        <div>
            <Helmet>
                <title></title>
                <link rel="canonical" href="https://www.tacobell.com/" />
                <meta name="description" content=""/>
            </Helmet>
            <main id="insideMain" tabIndex="-1">
                <div className="container">
                    <div className="row">
                        <article className="col-xs-12">
                            <h1>عنوان الصفحة الرئيسية</h1>
                            <p>خمسة قرون من الزمن لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة
                                والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق "ليتراسيت"
                                (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور برامج
                                النشر الإلكتروني مثل "ألدوس بايج مايكر" (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص
                                لوريم إيبسوم.</p>
                            <p>خمسة قرون من الزمن لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة
                                والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق "ليتراسيت"
                                (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور برامج
                                النشر الإلكتروني مثل "ألدوس بايج مايكر" (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص
                                لوريم إيبسوم.</p>
                        </article>
                    </div>
                </div>
            </main>
            <CarouselProducts/>
        </div>
    )
}