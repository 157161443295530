import CarouselProducts from "../Layouts/CarouselProducts";
import {useRef, useState} from "react";
import axios from "axios";
import swal from 'sweetalert';
import {Helmet} from "react-helmet-async";
import { useTranslation } from 'react-i18next';
import {baseURL, CONTACT} from "../API/Apis";

export default function Contact ()
{
    const [contactData , setContactData] = useState({
        full_name : "",
        company : "",
        phone : "",
        email : "",
        address : "",
        subject : "",
    });
    const {t , i18n} = useTranslation();


    const subjectRef = useRef('');

    const handleInput = (e) => {
        e.persist();
        setContactData({...contactData, [e.target.name]:e.target.value });

    }

    const addContact = (e) => {
        e.preventDefault();

        // Send Form Data

            const formData = new FormData();
            formData.append('full_name', contactData.full_name);
            formData.append('company', contactData.company);
            formData.append('phone', contactData.phone);
            formData.append('email', contactData.email);
            formData.append('address', contactData.address);
            formData.append('subject', contactData.subject);

            axios.post(`${baseURL}/${CONTACT}`,formData).then(res=>{
                if(res.data.status === 200)
                {
                    //Reset Inputs After Submit
                    setContactData({
                        full_name : "",
                        company : "",
                        phone : "",
                        email : "",
                        address : "",
                        subject : "",
                    });
                    subjectRef.current.value = "";
                    swal("Success",res.data.message,"success");

                }
            });

    }

    return (
        <div>
            <Helmet>
                <title>Contact</title>
                <link rel="canonical" href="https://www.tacobell.com/" />

            </Helmet>
            <main id="insideMain" tabIndex="-1">
                <div className="container">
                    <div className="row">
                        <article className="col-xs-12">
                            <h1>اتصل بنا</h1>
                            
                            <form className="contacts" onSubmit={addContact}>
                                <ol>
                                    <li><label htmlFor="">{t('full_name')}</label><input type="text" name='full_name' id='full_name' value={contactData.full_name} onChange={handleInput}/></li>
                                    <li><label htmlFor="">{t('company')}</label><input type="text" name="company" id="company" value={contactData.company} onChange={handleInput}/></li>
                                    <li><label htmlFor="">{t('phone')}</label><input type="number" name="phone" id="phone" value={contactData.phone} onChange={handleInput}/></li>
                                    <li><label htmlFor="">{t('email')}</label><input type="email" name="email" id="email" value={contactData.email} onChange={handleInput}/></li>
                                    <li><label htmlFor="">{t('address')}</label><input type="text" name="address" id="address" value={contactData.address} onChange={handleInput}/></li>
                                    <li><label htmlFor="">{t('subject')}</label><textarea name="subject" id="subject" ref={subjectRef} onChange={handleInput}></textarea></li>
                                    <li>
                                        <button className="bttn left" type="submit">{t('send')}</button>
                                    </li>
                                </ol>
                            </form>

                        </article>

                    </div>
                </div>
            </main>
            <CarouselProducts/>
        </div>
    )
}