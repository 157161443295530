import {useContext, useEffect, useState} from "react";
import {Link, useParams} from 'react-router-dom'
import axios from "axios";
import CarouselProducts from "../Layouts/CarouselProducts";
import {Helmet} from "react-helmet-async";
import {imageUrl} from "../imageUrl";
import {Language} from "../LangContext/LangaugeContext";
import {baseURL, WEBSITE_CATEGORIES} from "../API/Apis";

export  default function Categories()
{
    const {brandId} = useParams();
    const locale = useContext(Language);
    const localeVal = locale.locale;
    const [categories , setCategories] = useState([]);
    const [brandData , setBrandData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${WEBSITE_CATEGORIES}/${brandId}`,{
            }).then((res) => {
                setBrandData(res.data.brandData)
                setCategories(res.data.categories)
                 window.localStorage.setItem('categories',res.data.categories);
            });
        };
        getData();
    }, []);
    return (
        <div>
            <Helmet>
                <title>Products</title>
                <link rel="canonical" href="https://www.tacobell.com/" />
                <meta name="description" content="products"/>
            </Helmet>
            <main id="insideMain" tabIndex="-1">
                <div className="container">
                    <div className="row">
                        <article className="col-xs-12">
                          <h1>
                              {
                                  brandData.map((brand , index)=>
                                      brand.translations.map((translation , index)=>
                                              <div key={index}>
                                                  <Link underline="hover" color="inherit" href="/">
                                                      {translation.locale === localeVal ? translation.brand_name : ''}
                                                  </Link>
                                              </div>
                                      )
                                  )
                              }
                          </h1>
                            <ul className="proList">
                                {categories  ?
                                    categories.map((category, index) =>
                                        <li key={index}><Link to={`/${localeVal}/related-products/${brandId}/${category.id}`} state={{ brandData: brandData }}>
                                            <img src={`${imageUrl}categories/${category.category_image}`} alt=""/></Link>
                                            {category.translations.map((translation, index) =>
                                                translation.locale === localeVal ? <span key={index}>{translation.category_name}</span> : ''
                                            )}
                                        </li>
                                    ) : ''
                                }
                            </ul>
                        </article>
                    </div>
                </div>
            </main>
            <CarouselProducts/>
        </div>
    )
}