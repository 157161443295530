import {Link} from 'react-router-dom'
import axios from "axios";
import {useContext, useEffect, useRef, useState} from "react";
import {Language} from "../LangContext/LangaugeContext";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {baseURL, LANGUAGES, SET_LOCALE} from "../API/Apis";

export default function Footer ()
{
    const locale = useContext(Language);
    const localeVal = locale.locale;
    const navigate = useNavigate()
    const last_segment = window.location.pathname.split(`/`);
    const [languages , setLanguages] = useState([]);
    const {t , i18n} = useTranslation();

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 col-xs-12">
                        <ul className="footerLinks">
                            <li><Link to="/">{t('home')}</Link></li>
                            <li><Link to={`${localeVal}/about-company`}>{t('about_us')}</Link></li>
                            <li><Link to={`${localeVal}/products`}>{t('products')}</Link></li>
                            <li><Link to="">{t('companies')}</Link></li>
                            <li><Link to={`${localeVal}/contact`}>{t('contact_us')}</Link></li>
                        </ul>
                        <p className="copyright">{t('copyRight')}</p>
                        {/*<p style={{fontSize: "10px"}}>{t('designed')} <Link target="_blank" to="https://samatek.com">{t('samatek')}</Link></p>*/}
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <ul className="social">
                            <li>{t('follow_us')}</li>
                            <li><Link to="https://www.facebook.com/people/Andraos-For-Agriculture-And-Trading/100057654640222/" target={'_blank'} className="fa fa-facebook-square"><span className="sr-only">FaceBook</span></Link>
                            </li>
                            {/*<li><Link to="#" className="fa fa-instagram"><span*/}
                            {/*    className="sr-only">Twitter</span></Link></li>*/}
                            {/*<li><Link to="#" className="fa fa-google-plus-square"><span*/}
                            {/*    className="sr-only">Google Plus</span></Link></li>*/}
                            {/*<li><Link to="#" className="fa fa-linkedin-square"><span className="sr-only">Linkedin</span></Link>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}